/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');

import 'bootstrap';
import 'datatables';
import 'select2';
import 'daterangepicker';
import './jquery.sumoselect';

window.$.fn.select2.defaults.set('theme', 'bootstrap4');

window.trimSumoSelectOptions = id => {
    for (const element of $(id).find('option')) {
        element.innerHTML = element.innerHTML.trim();
    }
};
